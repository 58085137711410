.tooltip-container {
    width: 188px;
    height: 130px;
    padding: 10px 20px;
    margin: 0px;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
    background-color : #262c4f;
}

.tooltip-container-sm {
    width: 188px;
    height: 80px;
    padding: 10px 20px;
    margin: 0px;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
    background-color : #262c4f;
}

.tooltip-title {
    font-family: SUIT;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    color: #d7d7d7;
    display: flex;
    justify-content: center;
    padding-bottom: 2px;
}

.tooltip-title-line {
    width: 100%;
    margin: 5px 0 10px;
    justify-content: center;
    height: 1px;
    border : 0;
    background-color: #181a35;
    opacity: 0.8;
}

.tooltip-box {
    border-width: 0px;
    padding : 5px 0;
}

.tooltip-legend1{
    color: #477AFF
}
.tooltip-legend2{
    color: #33d5eb
}
.tooltip-legend3{
    color: #9361ff
}


.tooltip-label-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.tooltip-label {
    font-family: SUIT;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

.tooltip-value{
    color : rgb(215, 215, 215);
}
