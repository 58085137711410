@font-face {
    font-family: 'SUIT';
    src: url('./SUIT-Regular.otf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'SUIT';
    src: url('./SUIT-Medium.otf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'SUIT';
    src: url('./SUIT-Bold.otf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Gilroy';
    src: url("./Gilroy-Bold.otf") format('truetype');
}

